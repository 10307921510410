<template>
  <div>
    <section>
      <div class="container-terms">
        <Checkbox :onCheckChange="handleCheckboxChange" :themeCheckbox="themeCheckout" />
        <div
          class="d-flex align-items-center mb-2 cursor-pointer"
          @click="openModal"
        >
          <svg width="17" height="19" viewBox="0 0 17 19" fill="none">
            <path
              d="M4 6.03461C3.53056 6.03461 3.15 6.41517 3.15 6.88461C3.15 7.35406 3.53056 7.73461 4 7.73461V6.03461ZM10 7.73461C10.4694 7.73461 10.85 7.35406 10.85 6.88461C10.85 6.41517 10.4694 6.03461 10 6.03461V7.73461ZM4 9.30385C3.53056 9.30385 3.15 9.6844 3.15 10.1538C3.15 10.6233 3.53056 11.0038 4 11.0038V9.30385ZM10 11.0038C10.4694 11.0038 10.85 10.6233 10.85 10.1538C10.85 9.6844 10.4694 9.30385 10 9.30385V11.0038ZM4 12.5731C3.53056 12.5731 3.15 12.9536 3.15 13.4231C3.15 13.8925 3.53056 14.2731 4 14.2731V12.5731ZM7 14.2731C7.46944 14.2731 7.85 13.8925 7.85 13.4231C7.85 12.9536 7.46944 12.5731 7 12.5731V14.2731ZM4.75 0.15C4.28056 0.15 3.9 0.530558 3.9 1C3.9 1.46944 4.28056 1.85 4.75 1.85V0.15ZM15.5606 1.38301L16.1193 0.742362L16.1193 0.74236L15.5606 1.38301ZM15.15 14.7308C15.15 15.2002 15.5306 15.5808 16 15.5808C16.4694 15.5808 16.85 15.2002 16.85 14.7308H15.15ZM11.5 2.76538H2.5V4.46538H11.5V2.76538ZM2.5 2.76538C1.31541 2.76538 0.15 3.62565 0.15 4.92308H1.85C1.85 4.77608 2.02775 4.46538 2.5 4.46538V2.76538ZM0.15 4.92308V16.6923H1.85V4.92308H0.15ZM0.15 16.6923C0.15 17.9898 1.31542 18.85 2.5 18.85V17.15C2.02774 17.15 1.85 16.8393 1.85 16.6923H0.15ZM2.5 18.85H11.5V17.15H2.5V18.85ZM11.5 18.85C12.6846 18.85 13.85 17.9898 13.85 16.6923H12.15C12.15 16.8393 11.9723 17.15 11.5 17.15V18.85ZM13.85 16.6923V4.92308H12.15V16.6923H13.85ZM13.85 4.92308C13.85 3.62565 12.6846 2.76538 11.5 2.76538V4.46538C11.9722 4.46538 12.15 4.77608 12.15 4.92308H13.85ZM4 7.73461H10V6.03461H4V7.73461ZM4 11.0038H10V9.30385H4V11.0038ZM4 14.2731H7V12.5731H4V14.2731ZM4.75 1.85H14.5V0.15H4.75V1.85ZM14.5 1.85C14.7072 1.85 14.8864 1.92282 15.002 2.02367L16.1193 0.74236C15.6724 0.352725 15.0884 0.15 14.5 0.15V1.85ZM15.002 2.02367C15.1142 2.12145 15.15 2.22697 15.15 2.30769H16.85C16.85 1.69477 16.5696 1.13506 16.1193 0.742362L15.002 2.02367ZM15.15 2.30769V14.7308H16.85V2.30769H15.15Z"
              fill="#999E9D"
            />
          </svg>
          <p class="text-terms ml-2 mt-2">Termos e condições do contrato</p>
        </div>
      </div>
    </section>
    
    <BaseModal
      :title="'Termos e Condições'"
      :is-open="showModal"
      :contractTerms="prop.product.contract_terms"
      @close="closeModal"
      :theme="themeCheckout"
    />
  </div>
</template>

<script setup>
import BaseModal from "../Modal/BaseModal.vue";
import Checkbox from "../Modal/Checkbox.vue";
import { ref } from "vue";

const prop = defineProps({
  product: Object,
  themeCheckout: Object
});

const showModal = ref(false);

function openModal() {
  showModal.value = true;
}
function closeModal() {
  showModal.value = false;
}

function handleCheckboxChange(isChecked) {
  if (isChecked) {
    openModal();
  }
}
</script>

<style lang="scss" scoped>
.container-terms {
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  color: var(--txt-color);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  cursor: pointer;
}

.text-terms {
  font-weight: 600;
  line-height: 1.2125rem;
  text-decoration-line: underline;
  margin-left: 8px;
}

.text-check {
  font-weight: 400;
  line-height: 1.2125rem;
}
</style>

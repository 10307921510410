<template>
  <div class="content-terms">
    <b-form-checkbox
      v-model="checkContractTermsRead"
      @change="emitCheckChange"
      class="d-flex align-items-center"
      :style="{ '--main-color': mainColor }"
    >
      <label class="text-sm term-text">
        Declaro que li e aceito os termos do contrato apresentado
      </label>
    </b-form-checkbox>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import store from '../../store';

const checkContractTermsRead = computed({
  get: () => store.getters.checkContractHasRead,
  set: (value) => {
    if (store.getters.checkContractHasRead !== value) {
      store.dispatch('updateContractTerms', value);
    }
  },
});

const props = defineProps({
  onCheckChange: Function,
  themeCheckbox: Object,
});

function emitCheckChange() {
  if (props.onCheckChange) {
    props.onCheckChange(checkContractTermsRead.value);
  }
}

const defaultColor = '#00E4A0';
const colors = {
  '#00E4A0': '#00E4A0',
  '#0070EB': '#0070EB',
  '#2133D2': '#2133D2',
  '#FF0C37': '#FF0C37',
  '#FFCB37': '#FFCB37',
  '#2ED7EE': '#2ED7EE',
  '#5D21D2': '#5D21D2',
  '#17191F': '#17191F',
  '#00e4a0': '#17191F',
  '#3483FA': '#3483FA',
  '#00AF7B': '#00AF7B',
};

const mainColor = computed(() => {
  if (!props.themeCheckbox || !props.themeCheckbox.theme_color) {
    return defaultColor;
  }
  return colors[props.themeCheckbox.theme_color] || defaultColor;
});
</script>

<style lang="scss" scoped>
.content-terms {
  margin-top: 10px;
}

.term-text{
  width: 405px;
  height: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138.57%;
  display: flex;
  align-items: center;
  color: #333333;
}
</style>

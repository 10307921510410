<template>
  <div data-anima="top" class="collapse-contract-container">
    <div
      class="collapse-contract px-3"
      v-b-toggle.collapse-contract
      @click="cupom_active = !cupom_active"
    >
      <div class="text-cupom">
        <img src="@/assets/img/icon/calendar.svg" />
        <span>
          {{ this.charges }}x de {{ formatMoney(amount) }}
        </span>
      </div>
      <img src="@/assets/img/icon/arrow-fill.svg" />
    </div>

    <b-collapse id="collapse-contract" v-model="collapse" data-anima="top">
      <b-row>
        <b-col cols="12">
          <table class="w-full text-sm font-semibold text-left">
            <thead>
              <th style="padding: 10px;">Parcela</th>
              <th style="padding: 10px;">Cobrança</th>
              <th style="padding: 10px;">Valor</th>
            </thead>
            <tbody>
              <tr v-for="index in this.charges" :key="index" :class="index % 2 === 0 ? 'bg-table' : 'bg-white'">
                <td>
                  {{ index }}º parcela
                </td>
                <td>
                  {{ getInstallmentDate(index + 1) }}
                </td>
                <td>
                  {{ formatMoney(amount) }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import CountDown from "./CountDown.vue";

import CouponService from "@/services/resources/CouponService";

export default {
  name: "ContractCard",
  data() {
    return {
      id_product: 0,
      cupomResponse: {
        amount: 0,
        available: 0,
        due_date: null,
      },
      desconto_amount: 0,
      cupom: "",
      cupom_active: false,
      cupom_aplicado: false,
      cupom_error: false,
      collapse: false
    };
  },
  components: {
    CountDown,
  },
  props: {
    charges: {
      type: Number,
      required: false,
      default: () => 0
    },
    amount: {
      type: Number
    }
  },
  mounted() {
    if (this.cupom_checkout && this.discountAmount) this.setCupom();
  },
  methods: {
    formatMoney(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    getInstallmentDate(installmentNumber) {
      const today = new Date();
      const dueDate = new Date(today.setMonth(today.getMonth() + installmentNumber - 1)); // Incrementa meses com base no número da parcela
      return dueDate.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    }
  },
};
</script>

<style scoped>
td {
  font-weight: 400;
  padding: 15px 10px;
}

.bg-table {
  background-color: #fbfcfc;
}
/* // cupom */
.collapse-contract-container {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 15px auto 20px auto;
  transition: 0.3s;
}
@media screen and (max-width: 769px) {
  .collapse-contract-container {
    margin: 20px auto;
  }
}
.collapse-contract {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 0;
  outline: none !important;
}

.text-cupom {
  display: flex;
  align-items: center;
  gap: 10px;
}
.collapse-contract span {
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color);
}
#collapse-contract table{
  width: 100%;
  font-size: 13px;
  font-weight: 600;
}
.btn-cupom {
  font-size: 14px;
  height: 50px;
  width: 100%;
}
.column {
  background-color: #fbfcfc;
}
#code_cupom::placeholder {
  text-transform: initial !important;
}
.remover-cupom {
  font-size: 13px;
}

.btn-cupom:disabled {
  background: #f7f7f7 !important;
  color: #333 !important;
}
.btn-cupom:disabled:hover {
  transform: none !important;
}
.aplicado-cupom {
  margin: 0;
  margin-bottom: 10px;
  color: #81858e;
  font-size: 13px;
}
.badge-success {
  padding: 10px 15px;
  text-align: left;
  white-space: normal;
  font-weight: 600;
  line-height: 1.5;
  background-color: var(--transparent);
  color: var(--main-color);
  margin-bottom: 10px;
}
.timer span {
  color: var(--text-color);
  font-size: 13px;
  /* font-weight: 600; */
  display: block;
}
</style>
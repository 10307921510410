function validateCardBrand(cards, worldpayAllowedCards) {

    const cardPrefixes = {
        VISA: ["4"],
        MASTERCARD: ["51", "52", "53", "54", "55"],
    }; 

    if(!worldpayAllowedCards){
        return false;
    };
  
    const allowedCardBrands = worldpayAllowedCards
        .split(";")
        .map((worldpayAllowedCards) => worldpayAllowedCards.trim().toUpperCase())
        .filter((worldpayAllowedCards) => worldpayAllowedCards); 
    
        const isValid = cards.every((card) => {
            const cardNumber = card?.number || ""; 
  
            const cardBrand = Object.keys(cardPrefixes).find((brand) =>
                cardPrefixes[brand].some((prefix) => cardNumber.startsWith(prefix))
            );
  
            return cardBrand && allowedCardBrands.includes(cardBrand);
        });
        
        return isValid;
  }
  
  export {
    validateCardBrand
  };
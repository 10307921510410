<template>
  <div>
    <b-modal
      v-model="internalIsOpen"
      :title="title"
      hide-footer
      size="lg"
      centered
      @hidden="handleClose"
    >
      <template #modal-title>
        <div class="d-flex justify-content-between align-items-center w-100">
          <h5 class="mb-0">{{ title }}</h5>
        </div>
      </template>
  
      <div class="modal-body-custom">
        <v-md-preview :text="contractTerms.body" />
      </div>
  
      <Checkbox :themeCheckbox="theme"/>
    </b-modal>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Checkbox from './Checkbox.vue';

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "Termos e Condições",
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
  contractTerms: Object,
  theme: Object
});

const emit = defineEmits(["update:isOpen", "close"]);

const internalIsOpen = computed({
  get() {
    return props.isOpen;
  },
  set(value) {
    emit('update:isOpen', value);
  }
});

function handleClose() {
  internalIsOpen.value = false;
  emit('close');
}
</script>


<style lang="scss" scoped>
.modal-body-custom {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 0.5rem;
  max-height: 60vh;
  overflow-y: auto;
}

.contract-content {
  color: #333;
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
  line-height: 1.5rem;
  text-align: justify;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.content-terms {
  margin-top: 10px;
}
</style>
